:root {
  --vacay-font-color: #2b3674;
  --vacay-font-color-light: #daddf1;
  --bookedDaysColor: #6a48ff;
  --holidaysColor: #ff77b3;
  --selectedDatesColor: #8b77ff;
  --datesToUnbookColor: #6d7994;
}

.ant-tooltip-inner {
  white-space: nowrap;
  inline-size: min-content;
}

.pastDates {
  background: none;
  color: var(--vacay-font-color-light);
  border: 0;
  /*   border: 1px solid #cfc6f5;
 */
}

.bookedDays {
  background: var(--bookedDaysColor);
  color: white;
  border: 0;
}

.inactiveDays {
  background: none;
  border: 0;
}

.holidays {
  background: white;
  border: 2px solid;
  border-color: var(--bookedDaysColor);
}

.selectedDates {
  border-radius: 50%;
  background: #8b77ff;
  color: white;
  border: 0;
}

.datesToUnbook {
  background: var(--datesToUnbookColor);
  color: white;
  border: 0;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  border-radius: 13px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  outline: none;
  padding: 0px;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

/* .react-calendar__navigation button:enabled:hover, */
/* .react-calendar__navigation button:enabled:focus { */
/*   background-color: #e6e6e6; */
/* } */
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 0.9em;
  font-family: 'DM Sans';
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  font-weight: lighter;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}



.react-calendar__tile {
  height: 36px;
  border-radius: 50%;
  font-size: 0.9em;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover {
  background-color: var(--selectedDatesColor);
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #8b77ff;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #8b77ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f4f7fe;
  color: var(--vacay-font-color);
}

.custom-card {
  width: 80%;
}

.border {
  border: 4px solid #000000;
}

.pagecontent {
  margin-top: 40px;
  margin-left: 10%;
}

.banner-image {
  width: 100%;
}

.profile-image-container {
  display: flex;
  justify-content: center;
}

.image-upload>input {
  display: none;
}

.profile-image {
  position: relative;
  bottom: 50px;
  border-radius: 50%;
  cursor: pointer;
  width: 95px;
  height: 95px;
}

.general-info {
  color: #757ca4;
  font-size: 18px;
}

.form-control {
  border: 1px solid rgba(43, 54, 116, 0.33) !important;
  border-radius: 16px !important;
  color: #2b3674 !important;
}

.pwd-chng-btn {
  display: flex;
  justify-content: center;
}

.save-profile {
  width: 250px;
  height: 46.29px;
  background: #384077;
  border-radius: 21px;
  color: white;
  font-style: normal;
  font-weight: 700;
  border: none;
  font-size: 20px;
}

.change-password-content {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.input-forms {
  width: 50%;
}

.toggle {
  cursor: pointer;
}

.input-group {
  margin: 30px 0px;
}

.image-label:hover .edit-icon {
  display: block;
}

.image-label {
  height: 10px;
}

.edit-icon {
  transition: opacity 0.2s ease-in-out;
  display: none;
  position: relative;
  bottom: 85px !important;
  left: 75px !important;
}

/* --------------------Landing Container------------------------ */

/* --------------------Landing Header Section------------------------ */

.hr-for-header {
  color: rgba(244, 247, 254, 0.3);
}

/* --------------------Landing Banner Section------------------------ */

.signup-btn {
  max-width: 170px;
  min-width: 150px;
  padding: 0px 20px;
  height: 52px;
  color: #384077;
  border: none;
  background: #f4f7fe;
  border-radius: 50px;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 18px;
}

.login-btn {
  font-family: "Poppins";
  height: 52px;
  width: 100px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.04em;
  color: #f4f7fe;
  background: transparent;
  border: none;
}

.main-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 80px;
  background: linear-gradient(90deg,
      #f4f7fe 1.98%,
      rgba(244, 247, 254, 0.25) 109.04%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.sub-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  margin: 40px 0px;
  color: #f4f7fe;
}

.landing-container {
  padding-top: 30px;
  background-color: #384077;
}

.side-img {
  width: 100%;
  position: relative;
  bottom: 40px;
}

.banner-content {
  margin: 90px 0px;
}

.get-start-btn {
  text-align: center;
}

.get-start-btn2 {
  margin: 20% 0%;
  text-align: center;
}

/* --------------------Planner Section------------------------ */
.planner-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 80px;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  margin-bottom: 6%;
  color: #f4f7fe;
}

.planner-heading-color {
  color: #969bba;
}

.screens {
  margin: 12px;
  border-radius: 30px;
  padding: 50px 25px !important;
}

.sc-1-personal {
  background-color: #f9e5a1;
}

.sc-2-personal {
  background-color: #ffc7c6;
  height: 97%;
}

.sc-3-personal {
  background-color: #c1f3f0;
  padding-bottom: 0px !important;
}

.sc-1-business {
  background-color: #F8DEDE;
}

.sc-2-business {
  background-color: #CBE4FB;
  height: 97%;
}

.sc-3-business {
  background-color: #D8FAF7;
  padding-bottom: 0px !important;
}

.box-heading {
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
}

.box-sub-heading {
  line-height: 30px;
  font-size: 20px;
  font-family: 'Poppins';
}

.planner-img {
  width: 100%;
  position: relative;
  left: 32px;
}

.signup-btn-container {
  display: flex;
  justify-content: center;
}

.planner-sc3-image {
  width: 100%;
  margin-top: 115px;
  position: relative;
  top: 59px;
}

/* --------------------Footer Section------------------------ */
.footer-container {
  margin-top: 2%;
  padding-bottom: 6%;
}

.footer-input {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  width: 500px;
}

.footer-btn {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.8) 0%, #f3b0ae 100%);
  border-radius: 10px;
  width: 150px;
  font-family: "Poppins";
  margin-left: 20px;
  font-weight: 700;
  font-size: 18px;
  padding: 0px 20px;
  border: none;
}

.footer-section {
  margin-top: 20px;
  display: flex;
}

.input-field {
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  color: #daddf1 !important;
}

/* -----------------------Get Started---------------------- */
.getstart-btn {
  margin: 30px;
  text-align: center;
}

.getstart-heading {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 15% 0% !important;
}

.mb-screen {
  margin-bottom: 30%;
}

/* -------------------------------------------------- */
.remindme-label {
  font-family: "DM Sans";
  font-style: normal;
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  margin-top: 10%;
}

.remindme-input {
  width: 100% !important;
  margin-bottom: 15%;
}

.thank-screen {
  margin: 25% 0px;
  text-align: center;
}

.routeme {
  color: white;
}

@media screen and (max-width: 768px) {
  .landing-container {
    padding: 0px 10px;
    align-items: center;
    background-color: #384077;
  }

  .main-heading {
    font-size: 40px;
  }

  .side-img {
    width: 100%;
    position: relative;
    bottom: 0px !important;
  }

  .footer-input {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    width: 295px !important;
  }

  .planner-heading {
    font-size: 42px !important;
    text-align: left !important;
  }

  .planner-boxes {
    padding: 20px;
  }

  .planner-sc3-image {
    top: 45px !important;
  }

  .signup-btn {
    max-width: 143px;
    min-width: 122px;
    padding: 0px 25px;
    height: 50px;
    font-size: 14px;
  }

  .logo {
    width: 200px;
  }

  .main-heading {
    font-size: 40px !important;
    line-height: 55px;
  }

  .mobile-logo {
    padding-top: 20px;
  }

  .landing-container {
    min-height: 56rem;
  }

  .coverage-text {
    font-size: 15px !important;
    padding-top: 13px;
  }
}

/* ---------------------On Boarding---------------------------- */
.onboarding-container {
  /* border: 1px solid red; */
  margin: 150px auto;
  align-items: center;
}

.onboarding-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  letter-spacing: 0.02em;
}

.onboarding-subheading {
  color: rgba(56, 64, 119, 0.5);
}

.onboarding-light {
  color: rgba(56, 64, 119, 0.8);
}

.onboarding-button {
  width: 350px;
  height: 50px;
  left: calc(50% - 514px / 2);
  top: calc(50% - 76px / 2 + 152px);
  color: #ffffff;
  background: #384077;
  border-radius: 9px;
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 20px;
  border: none;
}

.onboarding-invites-sent {
  position: relative;
  top: -50px;
  font-size: 18px;
  font-weight: 800;
}

.Subtract-logo {
  width: 5%;
  height: 5%;
  margin: 10px 0px;
}

.onboarding-card-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #384077;
}

.onboarding-card-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #384077;
}

.textarea-container {
  margin-top: 30px;

  display: flex;
  justify-content: center;
}

.onboarding-textarea {
  width: 600px !important;
  height: 180px;
  border: 1px solid #384077 !important;
  padding: 30px 40px !important;
  font-size: 18px !important;
  box-shadow: 1px 1px 20px #c3c3c34d !important;
}

.onboarding-send-invites {
  background: #384077b3;
  width: 150px;
  position: relative;
  bottom: 60px !important;
  left: 0px;
  height: 45px;
}

.italic {
  font-style: italic;
}

.height-308 {
  height: 308px !important
}

.btn-dark {
  background: #384077;
}

.card-bg-col {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.dot-active {
  background: rgba(56, 64, 119, 0.8);
}

.dot-inactive {
  background: rgba(56, 64, 119, 0.3);
}

.dot-active,
.dot-inactive {
  padding: 4px;
  margin: 10px;
  border-radius: 50% !important;
}

.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

.card-active {
  border: 1px solid #384077 !important;
  box-shadow: 1px 1px 20px #c3c3c34d !important;
}

.card-inactive,
.onboarding-cards {
  border: none !important;
  box-shadow: 1px 1px 20px #c3c3c34d !important;
}

.card-active:hover,
.card-inactive:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255, 0.3);
}

.btns {
  max-width: 335px;
  margin: 0px auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
  background: #959BBA;
  padding: 5px;
  border-radius: 50px;
  gap: 8px;
  margin-bottom: 50px;
  color: #2B3674 !important;

}

.btns button {
  color: #2B3674 !important;
  border-radius: 50px;
  width: 100%;
  border: none;
  font-weight: 700;
  font-size: 17px;
}

.coverage-text {
  color: white;
  font-size: 22px;
  font-style: italic;
}