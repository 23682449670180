/*
Template Name: Nazox -  Admin & Dashboard Template
Author: Themesdesign
Version: 2.0.0
Contact: themesdesign.in@gmail.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/color-picker";
@import "custom/plugins/session-timeout";
@import "custom/plugins/round-slider";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/datatable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/x-editable";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/kanbanboard";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";



@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

.Login-form {
    background: #384077;
}

.Login-form a {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #2B3674;
}

.Login-form .content-section {
    padding-left: 78px;
}

.Login-form .content-section p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 46px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #FFFFFF;
}

.Login-form .content-section h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 100%;
    color: #FFFFFF;
}

.Login-form .form-card {
    height: 100vh;
    background: #F4F7FE;
    border-radius: 20px 0px 0px 20px;
}

.Login-form .form-card h3 {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 68px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #2B3674;
}

.Login-form .form-card p {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #2B3674;
    margin-bottom: 0px;
}

.Login-form .form-card .social-btn {
    background: #FFFFFF;
    border: 1px solid #F4F7FE;
    border-radius: 9px;
}


.Login-form .form-card .btn-submit {
    background: #384077;
    border-radius: 9px;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 39px;
    color: #FFFFFF;
}
.invalid-password{
    color: red;
    font-size: 12px;
}
.Login-form .form-card .custom-link {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: rgba(43, 54, 116, 0.67);
}
.handle-border{
    border-bottom: 1px solid rgba(43, 54, 116, 0.33) !important;
    border: none;
    font-size: 16px;

}
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(43, 54, 116, 0.67);
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(43, 54, 116, 0.67);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(43, 54, 116, 0.67);
}

input {
    background: transparent;
    // border: 1px solid rgba(43, 54, 116, 0.33) !important;
}

input:focus-visible {
    outline: none !important
}

.checkbox {
    width: 20px !important
}

.forget {
    text-decoration-line: underline;
    cursor: pointer;
    font-size: 20px !important;
}

@media only screen and (max-width: 1024px) {
    .Login-form .content-section {
        padding-left: 30px;
    }
}
.custom-link {
    font-size: 19px !important;
    color: #2b3674ab;
}
@media only screen and (max-width: 600px) {
    .form-card {
        height: 100% !important;
        border-radius: 0px !important;
    }

    body {
        background: #F4F7FE !important;
    }

    .custom-link {
        font-size: 16px !important;
    }

    .Login-form .form-card {
        h3 {
            font-size: 24px;
            margin-top: 30px;
        }

        p {
            font-size: 18px;
        }
    }
}